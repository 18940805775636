.contact-form{
  padding-top: 70px;
  padding-bottom: 35px;
  .block{
    form{
      .form-group{
        padding-bottom: 15px;
        margin: 0px;
        .form-control{
          background: #F6F8FA;
          height: 60px;
          border: 1px solid #EEF2F6;
          box-shadow: none;
          width: 100%;
        }
      }
      .form-group-2{
        padding-bottom: 15px;
        margin: 0px;
        textarea{
          background: #F6F8FA;
          height: 135px;
          border: 1px solid #EEF2F6;
          box-shadow: none;
          width: 100%;
        }
      }
    }
    button{
      width: 100%;
      height: 60px;
      background: #47424C;
      border: none;
      color: #fff;
      font-size: 18px;
    }
  }
}


.address-block {
  li {
    margin-bottom:10px;
    i {
      margin-right: 15px;
      font-size: 20px;
      width: 20px;
    }
  }
}


.social-icons {
  margin-top: 40px;
  li {
    display: inline-block;
    margin:0 6px;
  }
  a {
    display: inline-block;
  }
  i{
    color: #2C2C2C;
    margin-right: 25px;
    font-size: 25px;
  }
}

.google-map {
    position: relative;
}

.google-map #map {
    width: 100%;
    height: 300px;
}

.contact-box{
  padding-top: 35px;
  padding-bottom: 58px;
  .block{
    img{
      width: 100%;
    }
    h2{
      font-weight: 300;
      color: #000;
      font-size: 28px;
      padding-bottom: 30px;
    }
    p{
      color: #5C5C5C;
      display: block;
    }
    
  }
}


